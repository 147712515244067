const bluebox = require('./bluebox');
const partykungen = require('./partykungen');
const partyninja = require('./partyninja');
const coolstuff = require('./coolstuff');
const partyhallen = require('./partyhallen');

const products = bluebox.concat(partykungen, partyninja, coolstuff, partyhallen);

const uniqueProducts = [...new Map(products.map((item) => [item.title, item])).values()];

const presenter = require('./presenter');

module.exports = {
  products: uniqueProducts,
  presenter,
};
